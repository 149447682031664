.Standings-module_standings-table_4ySBP__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  display: flex;
}

.Standings-module_standings-table--not-widget_P-lr5__Stat {
  min-height: 500px;
  padding: 20px 10px;
}

.Standings-module_standings-table__warning_dyzXs__Stat {
  width: 100%;
}

.Standings-module_standings-table__table_bjey3__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

.Standings-module_standings-table__group_rGPcX__Stat {
  text-transform: uppercase;
  color: var(--text-primary);
  padding: 0 10px 10px;
}

.Standings-module_standings-table__placeholder_zMML5__Stat {
  margin: 20px 0 0;
}

@media (max-width: 660px) {
  .Standings-module_standings-table_4ySBP__Stat {
    position: relative;
  }

  .Standings-module_standings-table__table_bjey3__Stat {
    border-bottom: 0;
    border-left: none;
    border-right: none;
  }

  .Standings-module_standings-table__placeholder_zMML5__Stat {
    margin: 40px 10px;
  }
}

.Controls-module_standings-controls_8Knjv__Stat {
  align-items: center;
  width: 100%;
  display: flex;
}

.Controls-module_standings-controls--not-widget_Eejhs__Stat {
  gap: 20px;
  padding: 0 20px 20px 10px;
}

.Controls-module_standings-controls--widget_YERIb__Stat {
  padding-bottom: 0;
}

@media (max-width: 660px) {
  .Controls-module_standings-controls--not-widget_Eejhs__Stat {
    overflow-x: scroll;
  }
}

